.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f28f16 !important;
  border-width: 2px;

}


.MuiOutlinedInputLabel-root.Mui-focused .MuiOutlinedInputLabel-notchedOutline {
  border-color: #f28f16 !important;
  border-width: 2px;

}


.MuiInputLabel-outlined.MuiInputLabel {
  color: #f28f16 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #f28f16 !important;
}



.header-align-right{
  display: flex;
  padding: 21px 0;
  justify-content: center;
}


.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.0) !important;
}










/* hide pagination of beekeeper table on dashboard */

.beekeepertable .Component-paginationToolbar-57{
  display: none !important;
  
}



/* badge position fixed */

.MuiBadge-anchorOriginTopRightRectangle {
 
  /* right: 2px !important; */
  top: -6px !important;
 
}



/* notifications tabs fixed */

.tabsheader {
  position:fixed !important;
  z-index: 1 !important;
}

.raphael-group-21-caption text:first-child{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 18px !important;
  font-weight: 500 !important ;
  fill:rgba(0, 0, 0, 0.87) !important;  
}

.raphael-group-21-caption text:last-child{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  /* color: rgba(0, 0, 0, 0.87) !important;
  font-size: 18px !important;
  font-weight: 500 !important ;
  fill:rgba(0, 0, 0, 0.87) !important;   */
}


/* hover background color for card on chat profile */

/* .MuiButtonBase-root:hover {
color: #303030 !important;
background-color: #fff !important; 
} */

.MuiToolbar-gutters {
  padding-left: 7px !important;
}


  /* @media screen and (min-width:12000px) {
  .widthClass{
    width: 60%;
    background-color: red;
    color: red;
  }
  
} */
